import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { AccountQB, Company } from '../clients-list';
import Breadcrumbs from '../../../shared/breadcrumb';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete, Box,
  Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputAdornment,
  InputLabel, MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField, Tooltip,
  Typography
} from '@mui/material';
import styles from '../../../../index.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import apiClient from '../../../shared/auth.interceptor';
import { errorToast } from '../../../shared/toast.service';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MainRoute } from '../../../../app';
import { UserRoute } from '../../userContainer';
import { formatTimeOffset } from '../../../shared/date-time.formater';
import Loader from '../../../shared/Loader/loader';
import { toast } from 'react-toastify';
import ConfirmModal from './confirm.modal';
import PostingTooltip from './common/tooltip';
import CommonTimeField from './common/time-field';
import axios from 'axios';
import CustomDatePicker from './common/data-time-field';

export interface JournalTemplateResponse extends JournalTemplateBase {
  startDate: string,
  endDate: string,
  postingTime: { hour: number, minute: number }
}

export interface JournalTemplateBase {
  _id?: string;
  active: boolean;
  realmId: string;
  lastPostingDate: string;
  nextPostingDate: string;
  // 1
  name: string,
  notes: string,
  // 2
  debitAccount: AccountQB | null,
  creditAccount: AccountQB | null,
  journalEntryNumberPrefix: string,
  description: string,
  // 3
  // startDate: DateTime | null,
  // endDate: DateTime | null,
  referenceAccount: AccountQB | null,
  balanceType: string,
  ratePerJournalEntry: string,
  // 4
  frequency: string,
  calcNumber: string,
  // postingTime: DateTime | null,
  referenceAccountPeriod: string,
  postingNumber: string
}

export interface JournalTemplate extends JournalTemplateBase {
  startDate: DateTime | null,
  endDate: DateTime | null,
  postingTime: DateTime | null,
}

interface TemplateFormProps {
  mode: 'create' | 'edit' | 'view';
  template?: JournalTemplate; // This type should be defined based on your data structure
  // onSave: (data: JournalTemplate) => void; // Callback to save the data
}

const daysOfWeek = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' }
];

const BaseFormComponent: React.FC<TemplateFormProps> = ({ mode }) => {
  const navigate = useNavigate();
  const { realmId } = useParams(); // Получение realmId из URL
  const { templateId } = useParams();
  const companies = useSelector((state: RootState) => state.companyList.companyList);
  const [selectedClient, setSelectedClient] = useState<Company | null>(null);
  const [accountList, setAccountList] = useState<AccountQB[]>([]);
  const [openedDebitAccount, setOpenDebitAccount] = React.useState(false);
  const [openedCreditAccount, setOpenCreditAccount] = React.useState(false);
  const [openedReferenceAccount, setOpenReferenceAccount] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(false);
  const [isDisableRepostLastTransaction, setDisableRepostLastTransaction] = React.useState(true);
  const [openConfirmModal, setConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isEndDateEnabled, setIsEndDateEnabled] = useState(false);
  const [monthlyCalcSpecific, setMonthlyCalcSpecific] = useState('0');
  const [monthlyPostSpecific, setMonthlyPostSpecific] = useState('0');
  const { control, handleSubmit, setValue, watch, reset, formState } = useForm<JournalTemplate>({
    defaultValues: {
      _id: '',
      active: true,
      realmId,
      // 1
      name: '',
      notes: '',
      // 2
      debitAccount: null,
      creditAccount: null,
      journalEntryNumberPrefix: '',
      description: '',
      // 3
      startDate: null,
      endDate: null,
      referenceAccount: null,
      balanceType: '',
      ratePerJournalEntry: '',
      // 4
      frequency: 'Daily',
      calcNumber: '1',
      postingTime: DateTime.now().setZone(selectedClient?.timezone).set({ hour: 23, minute: 59, second: 0 }),
      referenceAccountPeriod: '',
      postingNumber: '1'
    }, mode: 'onChange'
  });
  const frequency = watch('frequency');
  const referenceAccountPeriod = watch('referenceAccountPeriod');
  const calcNumber = watch('calcNumber');
  const postingNumber = watch('postingNumber');
  const startDateValue = watch('startDate');
  const description = watch('description');
  const notes = watch('notes');
  const name = watch('name');
  const journalEntryNumberPrefix = watch('journalEntryNumberPrefix');
  const isActive = watch('active');
  const crumbs = [
    { title: selectedClient?.firmName, href: `${MainRoute.User}${UserRoute.Clients}` },
    {
      title: selectedClient?.companyName, href: `${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}`
    },
    { title: 'Journal templates' }
    // { title: 'TimeZone: ' + selectedClient?.timezone }
  ];
  const accountFilter = () => {
    if (!accountList?.length) {
      return [];
    }
    return accountList?.filter((account: AccountQB) =>
      account.AccountType !== 'Accounts Receivable' &&
      account.AccountType !== 'Accounts Payable'
    );
  };


  const getAccounts = async () => {
    try {
      const accounts = await apiClient.get(`v1/user/accounts/${realmId}`);
      setAccountList(accounts.data || []);
    } catch (e) {
      if (mode === 'edit') {
        errorToast('Error getting company accounts');
      }
      setLoading(false);
    }
  };
  const getTemplate = async () => {
    try {
      const response = await apiClient.get(`/v1/journal/template/${templateId}`);
      if (response.status === 200 && response.data) {
        return response.data; // Возвращаем данные, только если они успешно получены
      }
    } catch (e) {
      errorToast('Error getting template');
      return null; // Возвращаем null в случае ошибки
    }
  };
  const prepareJournalTemplateData = (data: JournalTemplateResponse): JournalTemplate => {
    const timezone = selectedClient?.timezone;
    return Object.assign(data, {
      // lastPostingDate: data.lastPostingDate ? DateTime.fromISO(data.lastPostingDate) : null,
      // nextPostingDate: data.nextPostingDate ? DateTime.fromISO(data.nextPostingDate) : null,
      startDate: data.startDate ? DateTime.fromISO(data.startDate).setZone(timezone) : null,
      endDate: data.endDate ? DateTime.fromISO(data.endDate).setZone(timezone) : null,
      postingTime: data.postingTime ?
        DateTime.now().setZone(selectedClient?.timezone)
          .set({ hour: data.postingTime.hour || 23, minute: data.postingTime.minute || 59, second: 0 }) :
        null,

      // startDate: data.startDate ? DateTime.fromISO(data.startDate, { zone: 'utc' }).setZone(selectedClient?.timezone) :
      //   null,
      // endDate: data.endDate ? DateTime.fromISO(data.endDate, { zone: 'utc' }).setZone(selectedClient?.timezone) : null,
      // postingTime: data.postingTime ?
      //   DateTime.fromISO(data.postingTime, { zone: 'utc' }).setZone(selectedClient?.timezone) : null,

      // Убедитесь, что AccountQB также корректно обрабатываются, если они могут быть null
      debitAccount: data.debitAccount, // предположим, что AccountQB может быть null
      creditAccount: data.creditAccount,
      referenceAccount: data.referenceAccount


      ///

      // calcDailyPeriod: '',
      // referenceAccountPeriod: '0',
      // postingNumber: '1',
      // calcNumber: '1'
    });
  };

  const onSubmit = async (data: JournalTemplate) => {
    setIsLoading(true);
    try {
      let response;
      const toSave = Object.assign(data,
        {
          postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute },
          name: data.name.trim(), notes: data.notes.trim(), description: data.description.trim(),
          journalEntryNumberPrefix: data.journalEntryNumberPrefix.trim()
        });
      if (mode === 'create') {
        delete toSave._id;
        response = await apiClient.post(`/v1/journal/template`, toSave);
      } else {
        response = await apiClient.put(`/v1/journal/template/${toSave._id}`, toSave);
      }

      if (response.status === 200 || response.status === 201) {
        navigate(`${MainRoute.User}${UserRoute.Clients}${UserRoute.Templates}/${realmId}`);
      } else {
        errorToast(`Error ${mode === 'create' ? 'create' : 'edit'} Journal Template`);
      }
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.message) {
          errorToast(error.response.data.message);
        }
      } else {
        errorToast(`Error ${mode === 'create' ? 'create' : 'edit'} Journal Template`);
      }
      setIsLoading(false);
    }
  };
  const sendTestTransaction = async (data: JournalTemplate) => {
    try {
      // return;
      setConfirmModal(false);
      const toSave = Object.assign(data,
        { postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute } });
      const response = await apiClient.post(`/v1/journal/template/test`, toSave);

      if (response.status === 200 || response.status === 201) {
        toast.success('Test journal template sent successfully');
      } else {
        errorToast(`Failed to send test journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while sending test journal template`);
    }
  };
  const repostLastTransaction = async (data: JournalTemplate) => {
    try {
      // return;
      const toSave = Object.assign(data,
        { postingTime: { hour: data.postingTime?.hour, minute: data.postingTime?.minute } });
      const response = await apiClient.post(`/v1/journal/template/${data._id}/repost`, toSave);

      if (response.status === 200 || response.status === 201) {
        toast.success('Repost journal template successfully');
      } else {
        errorToast(`Failed to repost journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while repost journal template`);
    }
  };
  const getPageTitle = () => {
    switch (mode) {
      case 'create':
        return 'Create template';
      case 'edit':
        return 'Edit template';
      case 'view':
        return 'View template';
    }
  };
  const numberPattern = /^\d*\.?\d*$/;
  const daysArray = Array.from({ length: 30 }, (_, i) => i + 1); // Создаем массив значений от 1 до 30

  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingError, setIsLoadingError] = useState<boolean>(false);
  const handleSave = async (data: JournalTemplate) => {
    try {
      const toSave = {
        ...data,
        postingTime: {
          hour: data.postingTime?.hour,
          minute: data.postingTime?.minute
        }
      };

      let response;
      if (mode === 'create') {
        delete toSave._id;
        response = await apiClient.post(`/v1/journal/template`, toSave);
      } else {
        response = await apiClient.put(`/v1/journal/template/${toSave._id}`, toSave);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Journal template ${mode === 'create' ? 'created' : 'updated'} successfully`);
        // Отправка тестового запроса после успешного сохранения
        await sendTestTransaction(data);
        setConfirmModal(false);
      } else {
        errorToast(`Failed to send test journal template`);
      }
    } catch (error) {
      errorToast(`An error occurred while sending test journal template`);
    }
  };
  useEffect(() => {
    void getAccounts();
  }, []);
  useEffect(() => {
    if (mode === 'view') {
      setReadOnly(true);
    }
    if ((mode === 'edit' || mode === 'view') && templateId) {

      getTemplate()
        .then((data: JournalTemplateResponse) => {
          if (data) { // Проверяем, что data не null
            reset(prepareJournalTemplateData(data));
            if (data.frequency === 'Monthly' && data.calcNumber && +data.calcNumber < 30) {
              setMonthlyCalcSpecific('1');
            }
            if (data.frequency === 'Monthly' && data.postingNumber && +data.postingNumber < 30) {
              setMonthlyPostSpecific('1');
            }
            if (data.lastPostingDate) {
              setDisableRepostLastTransaction(false);
            }
          }
        })
        .catch(_ => {
          setIsLoadingError(true);
          errorToast('Failed to load template data');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [mode, templateId, reset, accountList]);
  useEffect(() => {
    const currentCompany = companies?.find((company) => company.realmId === realmId);
    setSelectedClient(currentCompany || null);
  }, [realmId]);
  return (
    <Box sx={{ position: 'relative' }}>
      <div className="mt-2">
        <Breadcrumbs crumbs={crumbs}/>
      </div>
      <div className="d-flex align-items-center my-4">
        <ArrowBackIcon onClick={() => navigate(-1)}
                       sx={{ fontSize: '2rem', cursor: 'pointer' }}/>
        <Typography sx={{ fontSize: '2rem', marginLeft: '1rem', fontWeight: 'bol  d' }}>{getPageTitle()}</Typography>
      </div>
      {isLoadingError ?
        <Box></Box> : <Box
          sx={{
            position: 'relative',
            height: 'calc(100vh - 250px)',
            width: 'calc(100vw - 380px)',
            overflowX: 'auto',
            overflowY: 'auto'
          }}
        >
          <Box sx={{ position: 'relative', minWidth: '1000px' }}>
            {mode !== 'create' && loading && <Loader/>}
            <form className="mt-5 w-100" onSubmit={handleSubmit(onSubmit)}>
              <div className="w-100 row gx-3 gx-xl-4 gx-xxl-5">


                {/*Block 1*/}
                <div className="col-3">
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                    Templates Info
                  </Typography>
                  <Controller name="name"
                              control={control}
                              rules={{
                                required: 'Template name is required',
                                minLength: { value: 3, message: 'The minimum length must be at least 3 characters.' },
                                maxLength: { value: 30, message: 'The maximum length must not exceed 30 characters.' },
                                validate: (value) => {
                                  if (value.trim().length === 0) {
                                    return 'Template name cannot contain only spaces';
                                  }
                                  if (value.trim().length < 3) {
                                    return 'The minimum length must be at least 3 characters.';
                                  }
                                  return true;
                                }
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  InputProps={{ disabled: readOnly }}
                                  label="Template Name"
                                  inputProps={{ maxLength: 30 }}
                                  error={!!error}
                                  helperText={error?.message}
                                  required
                                  sx={{
                                    fontSize: '1rem',
                                    margin: '0.5rem 0',
                                    input: {
                                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                      borderRadius: '.5rem'
                                    }
                                  }}
                                />
                              )}
                  />
                  <Box sx={{
                    textAlign: 'right', marginTop: '2px', marginRight: '0.2rem', fontSize: '0.7rem',
                    color: styles.mainGrey
                  }}>
                    {name?.length || 0}/30
                  </Box>
                  <Controller name="notes"
                              control={control}
                              rules={{
                                maxLength: { value: 250, message: 'Maximum length should be 250 characters' },
                                validate: (value) => {
                                  if (value.length !== 0 && value.trim().length === 0) {
                                    return 'The prefix cannot contain only spaces';
                                  }
                                  return true;
                                }
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  InputProps={{ disabled: readOnly }}
                                  multiline
                                  rows={14}
                                  error={!!error}
                                  helperText={error ? error.message : ''}
                                  label="Notes"
                                  inputProps={{ maxLength: 250 }}
                                  sx={{
                                    fontSize: '1rem',
                                    margin: '0.5rem 0',
                                    textArea: {
                                      border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                      borderRadius: '.5rem'
                                    }
                                  }}
                                />
                              )}
                  />
                  <Box sx={{
                    textAlign: 'right', marginTop: '2px', marginRight: '0.2rem', fontSize: '0.7rem',
                    color: styles.mainGrey
                  }}>
                    {notes?.length || 0}/250
                  </Box>
                </div>
                {/*Block 2*/}
                <div className="col-3">
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                    Journal Entry
                  </Typography>
                  <Controller
                    name="debitAccount"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disabled={readOnly || !accountList?.length}
                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                        value={field.value!}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        options={accountFilter()}
                        disableClearable
                        open={openedDebitAccount}
                        onOpen={() => setOpenDebitAccount(true)}
                        onClose={() => setOpenDebitAccount(false)}
                        getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        sx={{
                          fontSize: '1rem',
                          margin: '1.5rem 0 1rem 0',
                          border: `1px solid ${styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Debit Account"
                            required
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                       onClick={() => {
                                                         if (!readOnly) {
                                                           setOpenDebitAccount(!openedDebitAccount);
                                                         }
                                                       }}/>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={`debit-${option.Id}`}>
                            {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                          </li>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="creditAccount"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disabled={readOnly || !accountList?.length}
                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                        value={field.value!}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        options={accountFilter()}
                        disableClearable
                        open={openedCreditAccount}
                        onOpen={() => setOpenCreditAccount(true)}
                        onClose={() => setOpenCreditAccount(false)}
                        getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        sx={{
                          fontSize: '1rem',
                          margin: '0.5rem 0',
                          border: `1px solid ${styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Credit Account"
                            required
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                       onClick={() => {
                                                         if (!readOnly) {
                                                           setOpenCreditAccount(!openedCreditAccount);
                                                         }
                                                       }}/>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={`debit-${option.Id}`}>
                            {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                          </li>
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="journalEntryNumberPrefix"
                    control={control}
                    rules={{
                      required: 'Journal Entry Number Prefix is required',
                      maxLength: { value: 6, message: 'The prefix can have at most 6 characters' },
                      validate: (value) => {
                        if (value.trim().length === 0) {
                          return 'The prefix cannot contain only spaces';
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={readOnly}
                        fullWidth
                        label="Journal Entry Number Prefix"
                        inputProps={{ maxLength: 6 }}
                        error={!!error}
                        helperText={error ? error.message : ''}
                        required
                        sx={{
                          fontSize: '1rem',
                          margin: '0.5rem 0',
                          input: {
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem'
                          }
                        }}
                      />
                    )}
                  />
                  <Box sx={{
                    textAlign: 'right', marginTop: '2px', marginRight: '0.2rem', fontSize: '0.7rem',
                    color: styles.mainGrey
                  }}>
                    {journalEntryNumberPrefix?.length || 0}/6
                  </Box>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: 'Description name is required',
                      minLength: { value: 5, message: 'Minimum length should be 5 characters' },
                      maxLength: { value: 100, message: 'Maximum length should be 100 characters' },
                      validate: (value) => {
                        if (value.trim().length === 0) {
                          return 'The prefix cannot contain only spaces';
                        }
                        if (value.trim().length < 5) {
                          return 'Minimum length should be 5 characters.';
                        }
                        return true;
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={readOnly}
                        fullWidth
                        multiline
                        rows={8}
                        label="Description"
                        error={!!error}
                        helperText={error ? error.message : ''}
                        required
                        inputProps={{ maxLength: 100 }}
                        sx={{
                          fontSize: '1rem',
                          margin: '0.5rem 0',
                          textArea: {
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem'
                          }
                        }}
                      />
                    )}
                  />
                  <Box sx={{
                    textAlign: 'right', marginTop: '2px', marginRight: '0.2rem', fontSize: '0.7rem',
                    color: styles.mainGrey
                  }}>
                    {description?.length || 0}/100
                  </Box>
                </div>
                {/*Block 3*/}
                <div className="col-3">
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                    Journal Dates
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CustomDatePicker
                      name="startDate"
                      label="Start date"
                      control={control}
                      readOnly={readOnly}
                      timezone={selectedClient?.timezone}
                    />
                  </LocalizationProvider>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={readOnly}
                          checked={isEndDateEnabled}
                          onChange={(e) => {
                            setIsEndDateEnabled(e.target.checked);
                            if (!e.target.checked) {
                              setValue('endDate', null);
                            }
                          }}
                          sx={{
                            margin: '1rem 0',
                            color: styles.mainGreen,
                            '&.Mui-checked': {
                              color: styles.mainGreen
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: 20
                            }
                          }}
                        />
                      }
                      label="End Date"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '1rem'
                        }
                      }}
                    />
                  </FormGroup>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <CustomDatePicker
                      name="endDate"
                      label="End date"
                      control={control}
                      readOnly={readOnly}
                      isDateEnabled={isEndDateEnabled}
                      minDate={startDateValue || DateTime.fromObject({ year: 2000, month: 1, day: 1 })}
                      timezone={selectedClient?.timezone}
                      startDateValue={startDateValue}
                      isRequired={false}
                    />
                  </LocalizationProvider>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Calculations
                  </Typography>
                  <Controller
                    name="referenceAccount"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disabled={readOnly || !accountList?.length}
                        isOptionEqualToValue={(option, value) => option.Id === value.Id}
                        value={field.value!}
                        onChange={(_, newValue) => field.onChange(newValue)}
                        options={accountList}
                        disableClearable
                        open={openedReferenceAccount}
                        onOpen={() => setOpenReferenceAccount(true)}
                        onClose={() => setOpenReferenceAccount(false)}
                        getOptionLabel={(option) => `${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                        sx={{
                          fontSize: '1rem',
                          margin: '1.5rem 0 1rem 0',
                          border: `1px solid ${styles.lightGrey}`,
                          borderRadius: '.5rem'
                        }}
                        // PaperComponent={(props) => (
                        //   <Paper {...props} sx={{ minWidth: 400 }}/> // Здесь можно задать minWidth или width
                        // )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reference Account"
                            required
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowForwardIosIcon sx={{ fontSize: '1rem' }}
                                                       onClick={() => {
                                                         if (!readOnly) {
                                                           setOpenReferenceAccount(!openedReferenceAccount);
                                                         }
                                                       }}/>
                                </InputAdornment>
                              )
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={`debit-${option.Id}`}>
                            {`${option.AcctNum ? `${option.AcctNum} | ` : ''}${option.Name}`}
                          </li>
                        )}
                      />
                    )}
                  />
                  <FormControl>
                    <FormLabel className="my-3" required
                               sx={{
                                 '&.Mui-focused': { top: 0 },
                                 fontSize: '1rem',
                                 color: styles.mainGrey
                               }}>
                      Balance Type
                    </FormLabel>
                    <Controller
                      name="balanceType"
                      control={control}
                      rules={{
                        required: 'Balance Type is required'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <RadioGroup {...field} row>
                            <FormControlLabel
                              disabled={readOnly}
                              value="Accrual"
                              control={<Radio sx={{
                                color: styles.mainGreen,
                                '&.Mui-checked': {
                                  color: styles.mainGreen
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1rem'
                                }
                              }}/>}
                              label="Accrual"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: '1rem'
                                }
                              }}
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="Cash"
                              control={<Radio sx={{
                                color: styles.mainGreen,
                                '&.Mui-checked': {
                                  color: styles.mainGreen
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: '1rem'
                                }
                              }}/>}
                              label="Cash"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontSize: '1rem'
                                }
                              }}
                            />
                          </RadioGroup>
                          {error && (
                            <FormHelperText sx={{ color: styles.mainRed }}>{error.message}</FormHelperText>
                          )}
                        </>
                      )}
                    />
                  </FormControl>

                  <Controller
                    name="ratePerJournalEntry"
                    control={control}
                    rules={{
                      required: '% Rate Per Journal Entry is required',
                      pattern: {
                        value: numberPattern,
                        message: 'Invalid format, only numbers and one decimal point are allowed'
                      }
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={readOnly}
                        fullWidth
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        onBlur={(e) => {
                          field.onChange(e.target.value.trim());
                        }}
                        label="% Rate Per Journal Entry"
                        required
                        placeholder="0.0"
                        error={!!error}
                        helperText={error ? error.message : null}
                        sx={{
                          fontSize: '1rem',
                          margin: '1.5rem 0 0.5rem 0',
                          input: {
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem'
                          }
                        }}
                      />
                    )}
                  />
                </div>
                {/*Block 4*/}
                <div className="col-3">
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="mb-3">
                    Frequency
                    {/*<Tooltip className="ms-2" title={`Company Timezone: ${formatTimeOffset(selectedClient?.timezone)}`}>*/}
                    {/*  <InfoOutlinedIcon sx={{ color: styles.mainGrey, fontSize: '1rem', marginBottom: '0.5rem' }}/>*/}
                    {/*</Tooltip>*/}
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="frequency-label" required
                                sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                      Frequency
                    </InputLabel>
                    <Controller
                      name="frequency"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          disabled={readOnly}
                          labelId="frequency-label"
                          label="Frequency"
                          onChange={(e) => {
                            setValue('frequency', e.target.value);
                            switch (e.target.value) {
                              case 'Daily': {
                                setValue('calcNumber', '0');
                                setValue('postingNumber', '0');
                                break;
                              }
                              case 'Weekly': {
                                setValue('calcNumber', '6');
                                setValue('postingNumber', '6');
                                break;
                              }
                              case 'Monthly': {
                                setValue('calcNumber', '30');
                                setValue('postingNumber', '30');
                                setMonthlyCalcSpecific('0');
                                setMonthlyPostSpecific('0');
                                break;
                              }
                            }
                          }}
                          required
                          sx={{
                            width: '100%',
                            margin: '0.5rem 0',
                            border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                            borderRadius: '.5rem',
                            paddingRight: '1rem'
                          }}
                        >
                          <MenuItem value={'Daily'}>Daily</MenuItem>
                          <MenuItem value={'Weekly'}>Weekly</MenuItem>
                          <MenuItem value={'Monthly'}>Monthly</MenuItem>
                          {/*<MenuItem value={'Quarterly'}>Quarterly</MenuItem>*/}
                          {/*<MenuItem value={'Annual'}>Annual</MenuItem>*/}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                    Calculated and Submitted at
                  </Typography>
                  {frequency === 'Daily' && <div>
                    <CommonTimeField name="postingTime" control={control} readOnly={readOnly}
                                     timezone={selectedClient?.timezone}></CommonTimeField>
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Calculation period
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="calcDailyPeriod-label" required
                                  sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                        Period
                      </InputLabel>
                      <Controller
                        name="referenceAccountPeriod"
                        control={control}
                        defaultValue="0"
                        rules={{
                          required: 'Period is required'
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            disabled={readOnly}
                            labelId="calcDailyPeriod-label"
                            label="Period"
                            required
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setValue('referenceAccountPeriod', e.target.value);
                              setValue('postingNumber', '0');
                            }}
                            sx={{
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }}
                          >
                            <MenuItem value={'-1'}>Yesterday</MenuItem>
                            <MenuItem value={'0'}>Today</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Posting Date
                      <PostingTooltip/>
                    </Typography>
                    <FormControl>
                      <Controller
                        name="postingNumber"
                        control={control}
                        rules={{
                          required: 'Posting Date is required'
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <RadioGroup {...field} row
                            >
                              <FormControlLabel
                                disabled={readOnly || referenceAccountPeriod === '0'}
                                value="-1"
                                control={<Radio sx={{
                                  color: styles.mainGreen,
                                  '&.Mui-checked': {
                                    color: styles.mainGreen
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: '1rem'
                                  }
                                }}/>}
                                label="Yesterday"
                                sx={{
                                  '& .MuiFormControlLabel-label': {
                                    fontSize: '1rem'
                                  }
                                }}
                              />
                              <FormControlLabel
                                disabled={readOnly}
                                value="0"
                                control={<Radio sx={{
                                  color: styles.mainGreen,
                                  '&.Mui-checked': {
                                    color: styles.mainGreen
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: '1rem'
                                  }
                                }}/>}
                                label="Today"
                                sx={{
                                  '& .MuiFormControlLabel-label': {
                                    fontSize: '1rem'
                                  }
                                }}
                              />
                            </RadioGroup>
                            {error && (
                              <FormHelperText sx={{ color: styles.mainRed }}>{error.message}</FormHelperText>
                            )}
                          </>
                        )}
                      />
                    </FormControl>
                  </div>}
                  {frequency === 'Weekly' && <div>
                    <FormControl fullWidth>
                      <InputLabel id="calcDailyPeriod-label" required
                                  sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                        Day
                      </InputLabel>
                      <Controller
                        name="calcNumber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            disabled={readOnly}
                            labelId="calcDailyPeriod-label"
                            required
                            onChange={(e) => {
                              setValue('calcNumber', e.target.value);
                              if (referenceAccountPeriod === '0' && (+postingNumber > +e.target.value)) {
                                setValue('postingNumber', e.target.value);
                              }
                            }}
                            sx={{
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }}
                          >
                            {daysOfWeek.map(day => (
                              <MenuItem key={day.value} value={day.value}>
                                {day.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>

                    <CommonTimeField name="postingTime" control={control} readOnly={readOnly}
                                     timezone={selectedClient?.timezone}></CommonTimeField>
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Calculation period
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="calcDailyPeriod-label" required
                                  sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                        Period
                      </InputLabel>
                      <Controller
                        name="referenceAccountPeriod"
                        control={control}
                        defaultValue="0"
                        rules={{
                          required: 'Period is required'
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            disabled={readOnly}
                            labelId="calcDailyPeriod-label"
                            label="Period"
                            required
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setValue('referenceAccountPeriod', e.target.value);
                              if (e.target.value === '0' && (+postingNumber > +calcNumber)) {
                                setValue('postingNumber', calcNumber);
                              }
                            }}
                            sx={{
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }}
                          >
                            <MenuItem value={'-1'}>Last Week</MenuItem>
                            <MenuItem value={'0'}>This Week</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Posting Date
                      <PostingTooltip/>
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="calcDailyPeriod-label" required
                                  sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                        Day
                      </InputLabel>
                      <Controller
                        name="postingNumber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            disabled={readOnly}
                            labelId="calcDailyPeriod-label"
                            required
                            sx={{
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }}
                          >
                            {daysOfWeek.map(day => {
                              const disabled = referenceAccountPeriod === '0' && (+day.value > +calcNumber);
                              return (
                                <MenuItem key={day.value} value={day.value} disabled={disabled}>
                                  {day.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </div>}
                  {frequency === 'Monthly' && <div>
                    <RadioGroup row
                                onChange={(e) => {
                                  setMonthlyCalcSpecific(e.target.value);
                                  if (e.target.value === '1') {
                                    setMonthlyPostSpecific(e.target.value);
                                  }
                                }}
                                defaultValue="0"
                                value={monthlyCalcSpecific}
                    >
                      <FormControlLabel
                        disabled={readOnly}
                        value="0"
                        control={<Radio
                          onClick={() => setValue('calcNumber', '30')}
                          sx={{
                            color: styles.mainGreen,
                            '&.Mui-checked': {
                              color: styles.mainGreen
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem'
                            }
                          }}/>}
                        label="End of Month"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '1rem'
                          }
                        }}
                      />
                      <FormControlLabel
                        disabled={readOnly}
                        value="1"
                        control={<Radio sx={{
                          color: styles.mainGreen,
                          '&.Mui-checked': {
                            color: styles.mainGreen
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem'
                          }
                        }}/>}
                        label="Specific Date"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '1rem'
                          }
                        }}
                      />
                    </RadioGroup>
                    {monthlyCalcSpecific === '1' ?
                      (<Box className="d-flex">
                        <FormControl sx={{ minWidth: '6rem', marginRight: '1rem' }}>
                          <InputLabel id="calcDailyPeriod-label" required
                                      sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                            Day
                          </InputLabel>
                          <Controller
                            name="calcNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                {...field}
                                disabled={readOnly}
                                labelId="calcDailyPeriod-label"
                                label="Period"
                                required
                                onChange={(e) => {
                                  setValue('calcNumber', e.target.value);
                                  if (referenceAccountPeriod === '0' && (+postingNumber > +e.target.value)) {
                                    setValue('postingNumber', e.target.value);
                                  }
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 250
                                    }
                                  }
                                }}
                                sx={{
                                  width: '100%',
                                  margin: '0.5rem 0',
                                  border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                  borderRadius: '.5rem',
                                  paddingRight: '1rem'
                                }}
                              >
                                {daysArray.map(day => (
                                  <MenuItem key={day} value={day}>
                                    {day}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                        <Tooltip title={`${formatTimeOffset(selectedClient?.timezone)}`} arrow
                                 componentsProps={{
                                   tooltip: {
                                     sx: {
                                       bgcolor: styles.mainGrey,
                                       color: styles.mainWhite,
                                       maxWidth: 'none',
                                       whiteSpace: 'pre-wrap',
                                       fontSize: '10px'
                                     }
                                   },
                                   arrow: {
                                     sx: {
                                       color: styles.mainGrey
                                     }
                                   }
                                 }}
                        >
                          <div>
                            <CommonTimeField name="postingTime" control={control} readOnly={readOnly}
                                             timezone={selectedClient?.timezone}></CommonTimeField>
                          </div>
                        </Tooltip>
                      </Box>) :
                      (<div>
                        <CommonTimeField name="postingTime" control={control} readOnly={readOnly}
                                         timezone={selectedClient?.timezone}></CommonTimeField>
                      </div>)}
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Calculation period
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="calcDailyPeriod-label" required
                                  sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                        Period
                      </InputLabel>
                      <Controller
                        name="referenceAccountPeriod"
                        control={control}
                        defaultValue="0"
                        rules={{
                          required: 'Period is required'
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            {...field}
                            disabled={readOnly}
                            labelId="calcDailyPeriod-label"
                            label="Period"
                            required
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setValue('referenceAccountPeriod', e.target.value);
                              if (e.target.value === '0' && (+postingNumber > +calcNumber)) {
                                setValue('postingNumber', calcNumber);
                              }
                            }}
                            sx={{
                              width: '100%',
                              margin: '0.5rem 0',
                              border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                              borderRadius: '.5rem',
                              paddingRight: '1rem'
                            }}
                          >
                            <MenuItem value={'-1'}>Last Month</MenuItem>
                            <MenuItem value={'0'}>This Month</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <Typography variant="subtitle2" color="#3FC7F4" gutterBottom className="my-3">
                      Posting Date
                      <PostingTooltip/>
                    </Typography>
                    <RadioGroup row
                                onChange={(e) => {
                                  setMonthlyPostSpecific(e.target.value);
                                }}
                                defaultValue="0"
                                value={monthlyPostSpecific}
                    >
                      <FormControlLabel
                        disabled={readOnly || (referenceAccountPeriod === '0' && monthlyCalcSpecific === '1')}
                        value="0"
                        control={<Radio
                          onClick={() => setValue('postingNumber', '30')}
                          sx={{
                            color: styles.mainGreen,
                            '&.Mui-checked': {
                              color: styles.mainGreen
                            },
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem'
                            }
                          }}/>}
                        label="End of Month"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '1rem'
                          }
                        }}
                      />
                      <FormControlLabel
                        disabled={readOnly}
                        value="1"
                        control={<Radio sx={{
                          color: styles.mainGreen,
                          '&.Mui-checked': {
                            color: styles.mainGreen
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem'
                          }
                        }}/>}
                        label="Specific Date"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '1rem'
                          }
                        }}
                      />
                    </RadioGroup>
                    {monthlyPostSpecific === '1' ?
                      (<div>
                        <FormControl fullWidth>
                          <InputLabel id="calcDailyPeriod-label" required
                                      sx={{ '&.MuiInputLabel-shrink': { top: '20px', left: '0' }, top: '11px' }}>
                            Day
                          </InputLabel>
                          <Controller
                            name="postingNumber"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <Select
                                {...field}
                                disabled={readOnly}
                                labelId="calcDailyPeriod-label"
                                label="Period"
                                required
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 250
                                    }
                                  }
                                }}
                                sx={{
                                  width: '100%',
                                  margin: '0.5rem 0',
                                  border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                                  borderRadius: '.5rem',
                                  paddingRight: '1rem'
                                }}
                              >
                                {daysArray.map(day => {
                                  const disabled = referenceAccountPeriod === '0' && (day > +calcNumber);
                                  return (
                                    <MenuItem key={day} value={day} disabled={disabled}>
                                      {day}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </div>) :
                      (<></>)}
                  </div>}

                </div>
              </div>
              <Divider component="div" sx={{ margin: '1rem 0' }}/>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex justify-content-start mt-5 mb-3">
                  <Button variant="outlined"
                          color="info"
                          disabled={!formState.isValid || !isActive || !selectedClient?.connected}
                    // onClick={handleSubmit(sendTestTransaction)}
                          onClick={() => {
                            formState.isDirty ?
                              setConfirmModal(true) :
                              handleSubmit(sendTestTransaction)();
                          }}
                          sx={{
                            width: '296px'
                          }}
                  >Post a test transaction</Button>
                  <Tooltip title={isDisableRepostLastTransaction ? 'No transactions yet' : ''}
                           disableHoverListener={!isDisableRepostLastTransaction} arrow
                           componentsProps={{
                             tooltip: {
                               sx: {
                                 bgcolor: styles.mainGrey,
                                 color: styles.mainWhite,
                                 maxWidth: 'none',
                                 whiteSpace: 'pre-wrap',
                                 fontSize: '10px'
                               }
                             },
                             arrow: {
                               sx: {
                                 color: styles.mainGrey
                               }
                             }
                           }}
                  >
                <span>
                  <Button
                    variant="outlined"
                    color="info"
                    disabled={isDisableRepostLastTransaction || !isActive || !selectedClient?.connected}
                    onClick={handleSubmit(repostLastTransaction)}
                    sx={{ width: '296px', marginLeft: '2rem' }}
                  >
                    Repost the last transaction
                  </Button>
                </span>
                  </Tooltip>
                </div>
                <div className="d-flex justify-content-end mt-5">
                  <Button variant="outlined"
                          onClick={() => navigate(-1)}
                          sx={{
                            width: '157px',
                            color: styles.mainGrey, borderColor: styles.mainGrey,
                            '&:hover': { borderColor: styles.mainGrey }
                          }}
                  >Cancel</Button>
                  <Button variant="contained"
                          hidden={mode === 'view'}
                          disabled={!formState.isValid || isLoading}
                          type="submit"
                          sx={{ width: '139px', marginLeft: '2rem' }}
                  >Save</Button>
                </div>
              </div>
            </form>
          </Box>
        </Box>
      }
      <ConfirmModal
        open={openConfirmModal}
        handleSkip={handleSubmit(sendTestTransaction)}
        handleSave={handleSubmit(handleSave)}
        handleOnClose={() => setConfirmModal(false)}
      />
    </Box>
  );
};

export default BaseFormComponent;
