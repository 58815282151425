import React from 'react';
import { Typography, Button } from '@mui/material';
import apiClient from '../../../shared/auth.interceptor';
import { Company } from '../clients-list';
import styles from '../../../../index.module.scss';
import { toast } from 'react-toastify';
import BaseModal from '../../../shared/components/base.modal';
import { UserModel } from '../../../shared/auth.functions';
import { errorToast } from '../../../shared/toast.service';

interface TimeZoneSelectModalProps {
  open: boolean;
  handleClose: () => void;
  client: Company;
  onSuccess: (companies: Company[]) => void;
}

const DisconnectClientModal: React.FC<TimeZoneSelectModalProps> = ({ open, handleClose, onSuccess, client }) => {
  const handleUpdate = () => {
    apiClient.put<UserModel>(`/v1/user/clients/disconnect/${client.realmId}`
    ).then((user) => {
      toast.success('Client disconnected successfully');
      onSuccess(user.data.companies);
    }).catch(() => {
      errorToast('Failed to disconnect client');
    }).finally(() => {
      handleClose();
    });
  };


  return (
    <BaseModal open={open} handleClose={handleClose}>
      <h2 className="fw-bold my-3">
        Disconnect
      </h2>
      <img alt={'Logo'} src="/logo120.svg"/>
      <p className="my-5">Are you sure you want to <br/> disconnect the client?</p>
      <Typography sx={{ color: styles.mainGreen, textDecorationLine: 'underline', fontSize: '1.6rem' }}>
        {client?.companyName}
      </Typography>
      <div className="d-flex justify-content-between my-3">
        <Button onClick={handleClose} variant="contained" sx={{ mt: 2, bgcolor: styles.mainGrey, width: '136px' }}>
          No
        </Button>
        <Button onClick={handleUpdate} variant="contained" sx={{ mt: 2, bgcolor: styles.mainBlue, width: '136px' }}>
          Yes
        </Button>
      </div>
    </BaseModal>
  );
};

export default DisconnectClientModal;
