import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import firmReducer from './components/shared/redux/firmSlicer';
import firmListReducer from './components/shared/redux/firmListSlicer';
import companyListReducer from './components/shared/redux/companySlicer';
import currentRealmReducer from './components/shared/redux/currentRealmSlicer';
import currentUserReducer from './components/shared/redux/userSlicer';
import currentSubscriptionReducer from './components/shared/redux/subscriptionSlicer';

const rootReducer = combineReducers({
  currentFirm: firmReducer,
  firmList: firmListReducer,
  companyList: companyListReducer,
  currentRealm: currentRealmReducer,
  currentUser: currentUserReducer,
  currentSubscription: currentSubscriptionReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER']
      }
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;
