import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { MainRoute } from '../../../app';
import { UserRoute } from '../userContainer';
import { useNavigate } from 'react-router-dom';

const StripePricingTable: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const [clientReferenceId, setClientReferenceId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const currentSubscription = useSelector((state: RootState) => state.currentSubscription.currentSubscription);


  useEffect(() => {
    if (currentUser) {
      setClientReferenceId(currentUser._id);
      setEmail(currentUser.email);
    }

    // Динамическая загрузка скриптов Stripe
    const loadStripeScript = (src: string, id: string) => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.id = id;
        document.body.appendChild(script);
      }
    };

    loadStripeScript('https://js.stripe.com/v3/pricing-table.js', 'stripe-pricing-table-script');
    loadStripeScript('https://js.stripe.com/v3/', 'stripe-js-script');

    return () => {
      // Удаляем скрипты при размонтировании компонента, если нужно
      const stripePricingTableScript = document.getElementById('stripe-pricing-table-script');
      const stripeJsScript = document.getElementById('stripe-js-script');

      if (stripePricingTableScript) {
        stripePricingTableScript.remove();
      }

      if (stripeJsScript) {
        stripeJsScript.remove();
      }
    };
  }, []);

  const publishableKey: string | undefined = process.env.REACT_APP_PUBLISHABLE_KEY;
  const pricingTableId: string | undefined = process.env.REACT_APP_STRIPE_PRICING_TABLE;

  if (!publishableKey || !pricingTableId) {
    return <div>Error: Stripe key or pricing table ID is missing.</div>;
  }

  useEffect(() => {
    if(currentSubscription){
      navigate(MainRoute.User + UserRoute.Subscription);
    }
  }, []);
  return (
    <div style={{ height: '100%', overflow: 'auto'
      // , width: "1000px"
    }}>
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
        client-reference-id={clientReferenceId}
        customer-email={email}
      />
    </div>
  );
};

export default StripePricingTable;
