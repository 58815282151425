import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import './breadcrumbs.scss';


type Crumb = {
  title?: string;
  href?: string;
};

interface BreadcrumbsProps {
  crumbs: Crumb[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
  return (
    <Breadcrumb>
      {crumbs.map((crumb, index) => (
        <Breadcrumb.Item
          key={index}
          active={crumbs.length > 1 && index === crumbs.length - 1}
          href={crumb.href || ''}
          style={{ letterSpacing: '0.1rem' }}
        >
          {crumb.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
