import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import styles from '../../../index.module.scss';
import './generic.table.scss';

export interface TableColumn {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

interface TableProps {
  columns: TableColumn[];
  data: any[] | undefined;
}

const GenericTable: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <TableContainer
      component={Paper}
      className="custom-scrollbar"
      sx={{
        // borderRadius: '10px', // Скругление углов всей таблицы
        // maxHeight: '70vh',
        marginTop: '3rem',
        overflow: 'auto', // Обрезка контента по скругленным краям
        boxShadow: 'none',
        flexGrow: 1
      }}
    >

      <Table stickyHeader sx={{
        minWidth: 650,
        fontSize: '1rem'
      }} aria-label="simple table">
        <TableHead
          sx={{
            // '& .MuiTableRow-head': { // targeting the head row
            //   borderRadius: '10px 10px 0 0', // applying border-radius
            // },
            '& .MuiTableCell-head:first-of-type': { // targeting the first cell of the head row
              borderTopLeftRadius: '8px',
              borderEndStartRadius: '8px'
            },
            '& .MuiTableCell-head:last-of-type': { // targeting the last cell of the head row
              borderTopRightRadius: '8px',
              borderEndEndRadius: '8px'
            },
            '& .MuiTableCell-head': {
              borderBottom: 'none' // Убираем нижний бордер
            }
          }}>
          <TableRow
            sx={{
              backgroundColor: styles.mainGrey, // Затемнение заголовка таблицы
              border: 'none'
            }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                  height: '4rem',
                  fontWeight: 300,
                  color: 'white', backgroundColor: styles.mainGrey,
                  fontSize: '1rem'
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      height: '4rem',
                      border: 'none', // Убираем нижний бордер
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      fontSize: '1rem'
                    }}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;
