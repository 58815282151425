import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../shared/Loader/loader';
import apiClient from '../../shared/auth.interceptor';
import { doLogin } from '../../shared/auth.functions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { OAuthStatus } from './service';

const RedirectUrl: React.FC = () => {
  const location = useLocation();
  const baseURL = process.env.REACT_APP_BASE_URL as string;
  const redirectUrl = `${baseURL}/v1/auth/oauth2/callback`;
  const currentRealId = useSelector((state: RootState) => state.currentRealm.currentRealmId);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code') || '';
    const state = queryParams.get('state') || '';
    const realmId = queryParams.get('realmId') || '';

    // Функция для обработки аутентификации
    const handleAuth = async (code: string, state: string, realmId: string) => {
      try {
        const params = new URLSearchParams({ code, state, realmId }).toString();
        const response = await apiClient.get(`${redirectUrl}?${params}`);
        doLogin(response.data);
        window.opener.postMessage({ type: OAuthStatus.Success, realmId }, window.location.origin);
        window.close();
      } catch (error) {
        window.opener.postMessage({ type: OAuthStatus.Error }, window.location.origin);
        window.close();
      }
    };

    // Проверяем код и запускаем обработку
    if (code) {
      if (!currentRealId || realmId === currentRealId) {
        void handleAuth(code, state, realmId);
      } else {
        window.opener.postMessage({ type: OAuthStatus.DifferentRealmId }, window.location.origin);
        window.close();
      }
    } else {
      window.close();
    }
  }, [location, redirectUrl, currentRealId]); // При изменении location или redirectUrl срабатывает эффект

  return <Loader/>;
};

export default RedirectUrl;
