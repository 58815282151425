import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const DisconnectedPage: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
      border="1px solid #ddd"
      borderRadius="8px"
      maxWidth="400px"
      margin="15% auto"
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
    >
      <Typography variant="h5" gutterBottom>
        Your company has been disconnected from QuickBooks
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        To continue using our app, please reconnect to QuickBooks.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component="a"
        href="/"
      >
        Go to our site
      </Button>
    </Box>
  );
};
export default DisconnectedPage;
