export const validate = (data: { email?: string, password?: string, confirmpassword?: string }, type?: string) => {
  const errors: { email?: string, password?: string, confirmpassword?: string } = {};


  // EMAIL
  if (!data.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
    errors.email = 'Email address is invalid';
  } else {
    delete errors.email;
  }

  // PASSWORD
  if (!data.password) {
    errors.password = 'Password is required';
  } else if (data.password.length < 6) {
    errors.password = 'Password need to be 6 characters or more';
  } else {
    delete errors.password;
  }


  if (type === 'signup') {
    // CONFIRM PASSWORD
    if (!data.confirmpassword) {
      errors.confirmpassword = 'Confirm the password';
    } else if (data.confirmpassword !== data.password) {
      errors.confirmpassword = 'Passwords do not match';
    } else {
      delete errors.confirmpassword;
    }

    // CHECKLIST
    // if (data.isAccepted) {
    //   delete errors.isAccepted;
    // } else {
    //   errors.isAccepted = 'Accept our regulations';
    // }
  }
  return errors;

};

export interface ErrorResponse {
  [key: string]: string | undefined;
}

export const passwordValidator = (password: string): ErrorResponse => {
  const errors: ErrorResponse = {};
  if (password.length < 8) {
    errors.length = 'Use 8 or more characters';
  }
  if (!/[A-Z]/.test(password)) {
    errors.uppercase = 'Use upper case letters';
  }
  if (!/[a-z]/.test(password)) {
    errors.lowercase = 'Use lower case letters';
  }
  if (!/[0-9]/.test(password)) {
    errors.number = 'Use a number';
  }
  if (!/[!@#$%^&*]/.test(password)) {
    errors.special = 'Use a symbol';
  }
  return errors;
};
export const getPasswordErrorMessages = (errors: ErrorResponse) => {
  return ['length', 'uppercase', 'lowercase', 'number', 'special']
    .map(key => errors[key])
    .filter(Boolean) // Фильтр удалит значения `undefined`
    .join('\n'); // Соединяем ошибки в одну строку с разделителем в виде переноса строки
};
