import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from '../../../index.module.scss';

interface AddNewFirmModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

const BaseModal: React.FC<AddNewFirmModalProps> = ({ children, open, handleClose }) => (
  <Modal open={open} onClose={handleClose}>
    <Box sx={{
      position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400,
      bgcolor: styles.lightGrey, boxShadow: 24, p: 4, alignItems: 'center', textAlign: 'center',
      borderRadius: '0.5rem'
    }}>
      <IconButton
        onClick={handleClose}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <HighlightOffIcon/>
      </IconButton>
      {children}
    </Box>
  </Modal>
);

export default BaseModal;
