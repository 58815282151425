import React, { ChangeEventHandler } from 'react';
import { IconButton, TextField } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

interface TouchedFields {
  email: boolean;
  code: boolean;
  password: boolean;
  confirmPassword: boolean;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface Props {
  errors: FormErrors;
  touched: TouchedFields;
  passwordVisible: boolean;
  password: string;
  handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  handleInputBlur: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  name: string;
  helperText: string;
  error: boolean;
}

const SignUpPassInput: React.FC<Props> = ({
  passwordVisible, password, handleInputChange, handleInputBlur, setPasswordVisible, label, name,
  helperText, error
}) => {
  return (
    <TextField
      fullWidth
      sx={{
        whiteSpace: 'pre-line',
        position: 'relative'
      }}
      error={error}
      helperText={helperText}
      type={passwordVisible ? 'text' : 'password'}
      name={name}
      value={password}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      label={label}
      variant="outlined"
      margin="normal"
      InputProps={{
        endAdornment: (
          <IconButton
            sx={{
              cursor: 'pointer', marginRight: '0.5rem',
              position: 'absolute',
              right: 0,
              zIndex: 10
            }}
            onClick={() => setPasswordVisible(!passwordVisible)}
            edge="end"
            aria-label="toggle password visibility"
          >
            {passwordVisible ? <VisibilityOffOutlinedIcon fontSize="small"/> :
              <RemoveRedEyeOutlinedIcon fontSize="small"/>}
          </IconButton>
        )
      }}
    />
  );
};

export default SignUpPassInput;
