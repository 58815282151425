import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { FormControl, Button, TextField, Typography } from '@mui/material';
import styles from '../../index.module.scss';
import BaseModal from '../shared/components/base.modal';
import apiClient from '../shared/auth.interceptor';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFirm } from '../shared/redux/firmSlicer';
import { setCurrentFirmList } from '../shared/redux/firmListSlicer';
import { RootState } from '../../store';
import { errorToast } from '../shared/toast.service';
import { IUser } from './user.interface';
import { setCurrentCompanyList } from '../shared/redux/companySlicer';

interface AddNewFirmModalProps {
  open: boolean;
  edit: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

interface FormErrors {
  [key: string]: string | undefined;
}

interface FormData {
  firmName: string;
  oldFirmName?: string;
}

const AddNewFirmModal: React.FC<AddNewFirmModalProps> = ({ open, edit, handleClose, onSuccess }) => {
  const currentFirm = useSelector((state: RootState) => state.currentFirm.currentFirm);
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<{ firmName: boolean }>({
    firmName: false
  });
  const [firmNameLength, setFirmNameLength] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>({
    firmName: ''
  });
  const dispatch = useDispatch();
  const validateField = (name: keyof FormData, value: string): void => {
    value = value.trim();
    let newErrors: FormErrors = { ...errors };
    if (name === 'firmName') {
      if (!value) {
        setFirmNameLength(0);
        newErrors.firmName = 'Please enter the name';
      } else if (value.length < 3) {
        newErrors.firmName = 'Firm name must be more than 3 characters';
      } else if (value.length > 30) {
        newErrors.firmName = 'Firm name must be less than 30 characters';
      } else {
        delete newErrors.firmName;
      }
      if (value && value.length) {
        setFirmNameLength(value.length);
      }
    }

    setErrors(newErrors);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    validateField(name as keyof FormData, value);
  };
  const resetForm = () => {
    setFormData({ firmName: '' });
    setErrors({});
    setTouched({ firmName: false });
  };
  const handleModalClose = () => {
    setFirmNameLength(0);
    resetForm();
    handleClose();
  };
  const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const { name } = event.target;
    setTouched({ ...touched, [name]: true });
    validateField(name as keyof FormData, event.target.value);
  };
  const handleUpdate = () => {
    if (edit && currentFirm) {
      formData.oldFirmName = currentFirm;
      if (formData.firmName === currentFirm) {
        errorToast('Firm name is the same');
        return;
      }
    }
    apiClient.put<IUser>('/v1/user/firm', formData).then((response) => {
      toast.success(`Firm ${edit ? 'Name updated' : 'added'}  successfully`);
      dispatch(setCurrentFirm(formData.firmName.trim()));
      dispatch(setCurrentFirmList(response.data.firmNames));
      dispatch(setCurrentCompanyList(response.data?.companies || []));
      resetForm();
      onSuccess();
    }).catch(() => {
      errorToast('Failed to add firm');
      handleModalClose();
    });
  };
  useEffect(() => {
    if (edit) {
      setFormData({ firmName: currentFirm || '' });
      setFirmNameLength(currentFirm?.length || 0);
    }
  }, [edit, currentFirm]);
  const disableSendButton = Object.keys(errors).length > 0 || !formData.firmName;
  return (
    <BaseModal open={open} handleClose={handleModalClose}>
      <img alt={'Logo'} src="/logo120.svg"/>
      <p className="my-5">{edit ? 'Edit a firm name' : 'Create a new firm'}</p>
      <FormControl fullWidth margin="normal">
        <TextField
          fullWidth
          error={Boolean(errors.firmName && touched.firmName)}
          helperText={touched.firmName && errors.firmName}
          label="Firm name"
          type="text"
          name="firmName"
          value={formData.firmName}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          variant="outlined"
          required
          inputProps={{ maxLength: 30 }}
        />
        <Typography variant="caption" sx={{
          textAlign: 'right', marginTop: '2px', marginRight: '0.2rem', fontSize: '0.7rem', color: styles.mainGrey
        }}>
          {firmNameLength}/30
        </Typography>
      </FormControl>
      <Button disabled={disableSendButton} onClick={handleUpdate} variant="contained"
              sx={{ mt: 2, width: '100%', bgcolor: styles.mainBlue }}>
        {edit ? 'Update' : 'Create'}
      </Button>
    </BaseModal>
  );
};

export default AddNewFirmModal;
