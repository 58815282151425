// @ts-nocheck
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import image from './robodebt-logo.svg';

const PageNotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img
        src={image}
        alt="Logo"
        width="200"
        height="200"
        style={{ marginBottom: '20px' }}
      />
      <Typography variant="h5" component="div" align="center" gutterBottom>
        Oops! It seems like you've taken a wrong turn.
      </Typography>
      <Typography variant="body1" component="div" align="center" paragraph>
        The page you're looking for doesn't exist.
      </Typography>

      <Button component={Link} to="/" variant="contained" color="primary">
        Go Home
      </Button>
    </Box>
  );
};

export default PageNotFound;
