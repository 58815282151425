import { setCurrentCompanyList } from './redux/companySlicer';
import { Company } from '../user/clients/clients-list';
import { setCurrentFirmList } from './redux/firmListSlicer';
import { Dispatch } from '@reduxjs/toolkit';
import apiClient from './auth.interceptor';
import { errorToast } from './toast.service';
import { setCurrentUser } from './redux/userSlicer';
import { IUser } from '../user/user.interface';
import { setCurrentFirm } from './redux/firmSlicer';

export enum LocalStorageKey {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token'
}

export interface UserModel {
  email: string;
  name: string;
  role: string;
  firmNames: string[];
  companies: Company[];
}

interface LoginResponse {
  access_token: string,
  refresh_token: string,
  user: UserModel
}

export const doLogin = (response: LoginResponse) => {
  try {
    const { access_token, refresh_token } = response;
    if (access_token) {
      localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, access_token);
    }
    if (refresh_token) {
      localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh_token);
    }
  } catch (e) {
    console.log(e);
  }
};
export const userRefresh = async (dispatch: Dispatch): Promise<IUser> => {
  try {
    const response = await apiClient.get('/v1/user');
    const user = response.data;
    if (user) {
      dispatch(setCurrentUser(user));
      dispatch(setCurrentCompanyList(user?.companies || []));
      dispatch(setCurrentFirmList(user.firmNames));
      if (!user?.firmNames?.length) {
        dispatch(setCurrentFirm(null));
      }
    }
    return user;
  } catch (e) {
    errorToast('An error occurred while fetching clients');
    throw e;
  }
};
