import React, { useState } from 'react';
import './login.scss';
import logo from './assets/robodebt-logo.svg';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Link, useNavigate } from 'react-router-dom';
import useOAuth from '../redirectUrl/service';
import { Button, IconButton, TextField } from '@mui/material';
import { getPasswordErrorMessages, passwordValidator } from '../../validate';
import apiClient from '../../shared/auth.interceptor';
import styles from '../../../index.module.scss';
import { doLogin, userRefresh } from '../../shared/auth.functions';
import { errorToast } from '../../shared/toast.service';
import { MainRoute } from '../../../app';
import { UserRoute } from '../../user/userContainer';
import { useDispatch } from 'react-redux';
import axios from 'axios';

interface LoginData {
  email: string;
  password: string;
}

interface LoginErrors {
  [key: string]: string | undefined;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
  const [errors, setErrors] = useState<LoginErrors>({});
  const [touched, setTouched] = useState({ email: false, password: false });

  const baseURL = process.env.REACT_APP_BASE_URL;

  const validateField = (name: string, value: string) => {
    let fieldErrors = { ...errors };
    // Удаляем предыдущие ошибки пароля перед валидацией
    if (name === 'password') {
      ['length', 'uppercase', 'lowercase', 'number', 'special'].forEach(error => delete fieldErrors[error]);
    }
    switch (name) {
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          fieldErrors.email = 'Please enter a valid email address';
        } else {
          delete fieldErrors.email;
        }
        break;
      case 'password':
        const passwordErrors = passwordValidator(value);
        fieldErrors = { ...fieldErrors, ...passwordErrors };
        break;
      default:
        break;
    }

    return fieldErrors;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors(validateField(name, value)); // Валидация при каждом изменении
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTouched({ ...touched, [name]: true });
    setErrors(validateField(name, value)); // Валидация при потере фокуса
  };
  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!errors.email && !errors.password && loginData.email && loginData.password) {
      try {
        const response = await apiClient.post(`${baseURL}/v1/auth/login`, loginData);
        doLogin(response.data);
        await userRefresh(dispatch);
        navigate(`${MainRoute.User}${UserRoute.Clients}`);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
          }
        } else {
          errorToast('An error occurred during login');
        }
      }
    } else {
      errorToast('Please correct the errors before submitting');
    }
  };

  const disableButton = Object.keys(errors).length > 0 || !loginData.password || !loginData.email;

  const { loginWithIntuit } = useOAuth();

  return (
    <div className="container-auth-page">
      <div className="main-auth-form">
        <img alt="logo" src={logo} className="logo"/>
        <form className="input-form px-3" onSubmit={handleLogin}>
          <h3 className="fw-bold mb-4">Hello Again!</h3>
          <TextField
            className="w-100 my-2"
            error={Boolean(errors.email && touched.email)}
            label="Email"
            name="email"
            value={loginData.email}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            helperText={touched.email && errors.email}
            variant="outlined"
          />
          <TextField
            className="w-100 my-2"
            sx={{
              whiteSpace: 'pre-line',
              position: 'relative'
            }}
            error={Boolean(getPasswordErrorMessages(errors) && touched.password)}
            helperText={touched.password ? getPasswordErrorMessages(errors) : ''}
            label="Password"
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            value={loginData.password}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton
                  sx={{
                    cursor: 'pointer', marginRight: '0.5rem',
                    position: 'absolute',
                    right: 0,
                    zIndex: 10
                  }}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {passwordVisible ? <VisibilityOffOutlinedIcon fontSize="small"/> :
                    <RemoveRedEyeOutlinedIcon fontSize="small"/>}
                </IconButton>
              )
            }}
          />
          <Button disabled={disableButton} variant="contained" type="submit"
                  className="login_button w-100 my-2">Login</Button>
          <div className="need-help-div">
            <Link to={MainRoute.SignUp}>Create an account</Link>
            <Link to={MainRoute.ForgotPassword}>Forgot password</Link>
          </div>
        </form>

        <div className="or-text">or</div>
        <div className="text-center mb-2">
          <Button variant="outlined" onClick={() => loginWithIntuit(false)}
                  sx={{
                    fontFamily: 'AvenirMediumFont, sans-serif', fontSize: '15px', fontWeight: 600,
                    height: '36px', borderWidth: '2px', borderRadius: '4px', borderColor: styles.mainBlue,
                    letterSpacing: '0.5px', lineHeight: '16px', padding: '6px 15px 4px 15px',
                    '&:hover': {
                      borderWidth: '2px',
                      backgroundColor: styles.mainBlueRGBa
                    }
                  }}
          >Sign in with Intuit</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;

