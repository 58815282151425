import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CurrentRealmState {
  currentRealmId: string | null;
}

const initialState: CurrentRealmState = {
  currentRealmId: null
};

export const currentRealmSlice = createSlice({
  name: 'currentRealmId',
  initialState,
  reducers: {
    setCurrentRealm: (state, action: PayloadAction<string | null>) => {
      state.currentRealmId = action.payload;
    },
    resetCurrentRealmId: (state) => {
      state.currentRealmId = null;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentRealm, resetCurrentRealmId } = currentRealmSlice.actions;

export default currentRealmSlice.reducer;
