import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Box, Button, Typography } from '@mui/material';
import apiClient from '../../shared/auth.interceptor';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { errorToast } from '../../shared/toast.service';
import { UserRoute } from '../userContainer';
import { MainRoute } from '../../../app';
import { userRefresh } from '../../shared/auth.functions';
import { setCurrentSubscription } from '../../shared/redux/subscriptionSlicer';
import { UserSubscriptions } from '../user.interface';

export interface SubscriptionInfo {
  subscriptionStatus: string,
  productName?: string,
  productPrice?: number,
  productDescription?: string,
  trialEnd: string,
  currentPeriodStart: string,
  currentPeriodEnd: string,
  changePlanLink?: string,
  cancelAt?: Date,
  cancelAtPeriodEnd?: boolean,
  subscriptionTier?: UserSubscriptions
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

const SubscriptionControl: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const currentSubscription = useSelector((state: RootState) => state.currentSubscription.currentSubscription);
  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState<string | null>(null);
  const [isNextBilling, setIsNextBilling] = useState<boolean>(false);

  if (!currentUser) {
    return <div>No subscription information available.</div>;
  }

  const formatDate = (date?: string) => {

    if (!date) {
      return 'N/a';
    }
    const dt = DateTime.fromISO(date);
    return dt.toFormat('dd/LL/yy');
  };
  const cancelSubscription = () => {
    apiClient.post<SubscriptionInfo>('/stripe/subscription/cancel')
      .then((res) => {
        console.log(res.data);
        void userRefresh(dispatch);
        if (!res.data) {
          navigate(MainRoute.User + UserRoute.Clients);
        } else {
          dispatch(setCurrentSubscription(res.data));
        }
      })
      .catch(() => {
        errorToast('An error occurred while canceling subscription.');
      });

  };
  const changeSubscription = () => {
    apiClient.post<string>('/stripe/subscription/change')
      .then(res => {
        window.location.href = res.data;
      })
      .catch(() => {
        errorToast('An error occurred while fetching subscription.');
      });
  };
  const getStatusLabel = (status?: string) => {
    if (!status) {
      setCurrentSubscriptionStatus('');
      return;
    }
    switch (status) {
      case SubscriptionStatus.Active:
        setCurrentSubscriptionStatus('Active');
        break;
      case SubscriptionStatus.Trialing:
        setCurrentSubscriptionStatus('Trial');
        break;
      case SubscriptionStatus.Canceled:
        setCurrentSubscriptionStatus('Canceled');
        break;
    }
  };
  useEffect(() => {
    apiClient.get<SubscriptionInfo>('/stripe/subscription/info')
      .then(res => {
        if (!res.data) {
          dispatch(setCurrentSubscription(null));
          navigate(MainRoute.User + UserRoute.PricingTable);
          return;
        }
        dispatch(setCurrentSubscription(res.data));
        void userRefresh(dispatch);
      })
      .catch(_ => {
        errorToast('An error occurred while fetching subscription.');
        navigate(MainRoute.User + UserRoute.PricingTable);
      });

  }, []);
  useEffect(() => {
    getStatusLabel(currentSubscription?.subscriptionStatus);
    setIsNextBilling(!!currentSubscription?.cancelAtPeriodEnd);
  }, [currentSubscription]);
  const getTrialDiv = () => {
    if (currentSubscription?.subscriptionStatus !== SubscriptionStatus.Trialing) {
      return <div></div>;
    }
    const now = DateTime.now().setLocale('en');

    const trialEndDate = DateTime.fromISO(currentSubscription.trialEnd).setLocale('en');

    const diffInDays = trialEndDate.diff(now, 'days').days;
    const daysLeft = Math.ceil(diffInDays);

    const adjustedDaysLeft = daysLeft >= 0 ? daysLeft : 0;

    const dayWord = adjustedDaysLeft === 1 ? 'day' : 'days';

    const formattedDate = trialEndDate.toFormat('EEEE, dd MMMM');
    return (
      <Box sx={{
        display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(63, 199, 244, 0.1)',
        marginTop: '0.5rem', padding: '0.5rem', borderRadius: '0.5rem'
      }}>
        <div>
          <Typography sx={{ fontWeight: 'bold' }}>
            Your trial ends in {adjustedDaysLeft} {dayWord}
          </Typography>
          <Typography sx={{ fontSize: '0.9rem' }}>
            auto renewal on {formattedDate}
          </Typography>
        </div>
      </Box>
    );
  };
  return (
    <><h2 className="fw-bold mt-5">Subscription</h2>
      {currentSubscription ?
        (<>
          <Box
            sx={{
              width: '400px', height: '520px', backgroundColor: 'rgba(224, 227, 235, 0.15)',
              borderRadius: '24px', padding: '2rem', display: 'flex', flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}
            >
              <div className="d-flex justify-content-between align-items-baseline">
                <Typography variant="body2" color="textSecondary" component="div" sx={{ color: '#3FC7F4' }}>
                  Current Plan
                </Typography>
                <Typography variant="body2" component="div"
                            sx={{
                              backgroundColor: 'rgba(75, 189, 150, 0.1)', color: 'info.main',
                              padding: '0.5rem', borderRadius: '5px'
                            }}>
                  {currentSubscriptionStatus}
                </Typography>
              </div>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                {currentSubscription?.productName}
              </Typography>
              {getTrialDiv()}

              <Typography variant="body2"
                          sx={{ color: 'primary.light' }}>{currentSubscription?.productDescription}</Typography>
              <Box>
                <Typography variant="body2"
                            sx={{ color: 'primary.light' }}>
                  <b>
                    {isNextBilling ? 'Canceled:' : 'Next Billing Date:'}
                  </b> {formatDate(currentSubscription?.currentPeriodEnd)}
                </Typography>
                {!isNextBilling
                 && (
                   <>
                     <Typography variant="body2"
                                 sx={{ color: 'primary.light' }}>
                       <b>Price:</b> ${currentSubscription?.productPrice ? currentSubscription?.productPrice / 100 :
                       'N/a'}
                     </Typography>
                   </>
                 )}
              </Box>
            </Box>
            <Box className="d-flex justify-content-between">
              {!isNextBilling ?
                <Button variant="outlined" sx={{ width: '150px' }} color="secondary"
                        onClick={cancelSubscription}>Unsubscribe</Button> :
                <div></div>
              }
              <Button variant="contained" sx={{ width: '150px' }} onClick={changeSubscription}>Manage</Button>
            </Box>
          </Box></>) : (<div>No subscription</div>)}
    </>
  );
};

export default SubscriptionControl;
