import 'bootstrap/dist/css/bootstrap.min.css';
import './index.module.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './components/shared/Loader/loader';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    {/*<React.StrictMode>*/}
      <Provider store={store}>
        <PersistGate loading={<div><Loader/></div>} persistor={persistor}>
          <App/>
        </PersistGate>
      </Provider>
    {/*</React.StrictMode>*/}
  </ThemeProvider>
);
