import React, { useEffect, useState } from 'react';
import { FormControl, Button } from '@mui/material';
import { Company } from '../clients-list';
import apiClient from '../../../shared/auth.interceptor';
import styles from '../../../../index.module.scss';
import BaseModal from '../../../shared/components/base.modal';
import { toast } from 'react-toastify';
import TimeZoneAutocomplete, { timeZones, UsTimeZone } from '../../../shared/components/time-zone-autocomplete';
import { UserModel } from '../../../shared/auth.functions';
import { errorToast } from '../../../shared/toast.service';

interface TimeZoneSelectModalProps {
  open: boolean;
  handleClose: () => void;
  client: Company;
  onSuccess: (companies: Company[]) => void;
}

const TimeZoneSelectModal: React.FC<TimeZoneSelectModalProps> = ({ open, handleClose, onSuccess, client }) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState<UsTimeZone | null>(null);

  useEffect(() => {
    if (open && client.timezone) {
      setSelectedTimeZone(timeZones.find((tz) => tz.value === client.timezone) || null);
    } else {
      setSelectedTimeZone(null);
    }
  }, [open, client.timezone]);

  // const [openSelect, setOpenSelect] = useState(false); // Изменим имя для консистентности

  // const handleTimeZoneChange = (event: React.SyntheticEvent, newValue: UsTimeZone) => {
  //   setOpenSelect(false);
  //   setSelectedTimeZone(newValue);
  // };
  const handleUpdate = () => {
    if (selectedTimeZone) {
      apiClient.put<UserModel>(`/v1/user/clients/timezone/${client.realmId}`, {
        timezone: selectedTimeZone.value // Отправляем только значение timezone
      }).then((user) => {
        toast.success('Timezone updated');
        onSuccess(user.data.companies);
      }).catch((error) => {
        errorToast('Error updating timezone:');
      }).finally(() => {
        handleClose();
      });
    }
  };
  return (

    <BaseModal open={open} handleClose={handleClose}>
      <h2 className="fw-bold my-3">
        {client?.companyName} Time Zone
      </h2>
      <FormControl fullWidth margin="normal" sx={{ border: 'none' }}>
        {
          <TimeZoneAutocomplete
            selectedTimeZone={selectedTimeZone}
            onSelectTimeZone={setSelectedTimeZone}
          />
        }
      </FormControl>
      <Button onClick={handleUpdate} variant="contained" sx={{ mt: 4, width: '100%', bgcolor: styles.mainBlue }}>
        Update
      </Button>
    </BaseModal>
  );
};

export default TimeZoneSelectModal;
