import React from 'react';
import { Button, Typography } from '@mui/material';
import styles from '../../index.module.scss';
import BaseModal from '../shared/components/base.modal';

interface LogoutModalProps {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ open, handleClose, handleSuccess }) => {
  return (
    <BaseModal open={open} handleClose={handleClose}>
      <h2 className="fw-bold my-3">
        Log out
      </h2>
      <Typography variant="subtitle2" gutterBottom>
        Are you sure you want to log out?
      </Typography>
      <img alt={'Logo'} src="/logo120.svg"/>
      <div className="d-flex justify-content-between my-3">
        <Button onClick={handleClose} variant="contained" sx={{ mt: 2, bgcolor: styles.mainGrey, width: '136px' }}>
          No
        </Button>
        <Button onClick={handleSuccess} variant="contained" sx={{ mt: 2, bgcolor: styles.mainBlue  , width: '136px' }}>
          Yes
        </Button>
      </div>
    </BaseModal>
  );
};

export default LogoutModal;
