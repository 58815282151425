import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import PageNotFound from './pageNotFound';
import Login from './components/auth/login/login';
import RedirectUrl from './components/auth/redirectUrl/RedirectUrl';
import UserContainer, { UserRoute } from './components/user/userContainer';
import SignUp from './components/auth/Signup/SignUp';
import ForgetPassword from './components/auth/ForgetPassword/ForgetPassword';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import UserGuard from './components/shared/user.guard';
import DisconnectedPage from './components/auth/disconnected';

export enum MainRoute {
  IntuitRedirect = '/intuit-signin-redirect-url',
  User = '/user',
  SignIn = '/signin',
  SignUp = '/signup',
  ForgotPassword = '/forget-password',
  DisconnectedPage = '/disconnected',
}

export default function App() {

  return (
    <BrowserRouter>
      <ToastContainer position="top-right" autoClose={3000}/>
      <Routes>
        <Route
          path="/"
          element={
            <UserGuard><Navigate to={MainRoute.User + UserRoute.Clients}/></UserGuard>
          }
        />
        <Route path={MainRoute.IntuitRedirect} element={<RedirectUrl/>}/>
        <Route path={MainRoute.User + '/*'} element={
          <UserGuard>
            <UserContainer/>
          </UserGuard>
        }/>
        <Route path={MainRoute.SignIn} element={<Login/>}/>
        <Route path={MainRoute.SignUp} element={<SignUp/>}/>
        <Route path={MainRoute.ForgotPassword} element={<ForgetPassword/>}/>
        <Route path={MainRoute.DisconnectedPage} element={<DisconnectedPage/>}/>
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}
