import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import styles from '../../../../../index.module.scss';

interface CustomDatePickerProps {
  name: string;
  label: string;
  control: Control<any>;
  readOnly?: boolean;
  isDateEnabled?: boolean;
  minDate?: DateTime;
  maxDate?: DateTime;
  timezone?: string;
  startDateValue?: DateTime | null;
  validate?: (value: DateTime | null) => string | true;
  isRequired?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  label,
  control,
  readOnly = false,
  isDateEnabled = true,
  minDate = DateTime.fromObject({ year: 2000, month: 1, day: 1 }),
  maxDate = DateTime.fromObject({ year: 2100, month: 12, day: 31 }),
  timezone,
  startDateValue,
  validate,
  isRequired = true
}) => {
  // Default validation function if no custom validate function is provided
  const defaultValidate = (value: DateTime | string) => {
    if (typeof value === 'string') {
      return 'Please enter a valid date';
    }
    if (!value.isValid) {
      return 'Please enter a valid date';
    }
    const year = value.year;
    if (year < 2000 || year > 2100) {
      return 'The year must be between 2000 and 2100';
    }

    // Additional validation for end date if `startDateValue` is provided
    if (startDateValue && value < startDateValue) {
      return 'End date must be greater than Start Date';
    }

    return true;
  };
  const requiredValidate = (value: DateTime | null) => {
    if (!value) {
      return 'Date is required';
    }
    return defaultValidate(value);
  };
  const notRequiredValidate = (value: DateTime | null) => {
    if (!value) {
      return true;
    }
    return defaultValidate(value);
  };
  const rules = isRequired
    ? {
      required: 'Date is required',
      validate: validate || requiredValidate
    }
    : {
      validate: validate || notRequiredValidate
    };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <DatePicker
          disabled={!isDateEnabled || readOnly}
          label={label}
          value={value}
          onChange={onChange}
          timezone={timezone}
          ref={ref}
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            textField: {
              error:
                !!error ||
                !!(value && (value.year < 2000 || value.year > 2100)) ||
                (name === 'endDate' && !!(startDateValue && value && value < startDateValue)),
              helperText: error
                ? error.message
                : value && (value.year < 2000 || value.year > 2100)
                  ? 'The date must be between 2000 and 2100'
                  : name === 'endDate' && startDateValue && value && value < startDateValue
                    ? 'End date must be greater than Start Date'
                    : '',
              fullWidth: true,
              required: true,
              variant: 'outlined',
              sx: {
                width: '100%',
                margin: '0.5rem 0',
                border: `1px solid ${error ? 'red' : styles.lightGrey}`,
                borderRadius: '.5rem',
                paddingRight: '1rem'
              }
            }
          }}
        />
      )}
    />
  );
};

export default CustomDatePicker;
