import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../user/clients/clients-list';

export interface CompanyListState {
  companyList: Company[];
}

const initialState: CompanyListState = {
  companyList: []
};

export const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    setCurrentCompanyList: (state, action: PayloadAction<Company[]>) => {
      state.companyList = action.payload;
    },
    resetCompanyList: (state) => {
      state.companyList = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentCompanyList, resetCompanyList } = companyListSlice.actions;

export default companyListSlice.reducer;
