import React from 'react';
import { Typography, Button } from '@mui/material';
import styles from '../../../index.module.scss';
import BaseModal from '../../shared/components/base.modal';

interface NeedSubscribeModalProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
}

const NeedSubscribeModal: React.FC<NeedSubscribeModalProps> = ({ open, handleClose, onSuccess }) => {
  return (
    <BaseModal open={open} handleClose={handleClose}>
      <h2 className="fw-bold my-3">
        You've hit your limit.
      </h2>
      <p>
        It's time for Your upgrade!
      </p>
      <img alt={'Logo'} src="/diamond.svg"/>
      <p className="my-5">Please choose from one of our paid subscriptions.</p>
      <Typography sx={{ color: styles.mainGreen, textDecorationLine: 'underline', fontSize: '1.6rem' }}>
      </Typography>
      <div className="d-flex justify-content-center my-3">
        <Button onClick={onSuccess} variant="contained" sx={{ mt: 2, bgcolor: styles.mainBlue, width: '136px' }}>
          See plans
        </Button>
      </div>
    </BaseModal>
  );
};

export default NeedSubscribeModal;
