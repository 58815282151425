import React from 'react';
import { Button } from '@mui/material';
import BaseModal from '../../../shared/components/base.modal';

interface SaveChangesModalProps {
  open: boolean;
  handleSkip: () => void;
  handleSave: () => void;
  handleOnClose: () => void;
}

const SaveChangesModal: React.FC<SaveChangesModalProps> = ({ open, handleSkip, handleSave, handleOnClose }) => {
  return (
    <BaseModal open={open} handleClose={handleOnClose}>
      <h2 className="fw-bold my-3">
        Save Changes Before Submitting
      </h2>
      <img alt={'Logo'} src="/logo120.svg"/>
      <p className="my-5">
        You have unsaved changes. Do you want to save them before submitting your template?
      </p>
      <div className="d-flex justify-content-between my-3">
        <Button onClick={handleSkip} variant="outlined" sx={{ mt: 2, width: '136px' }}>
          Skip
        </Button>
        <Button onClick={handleSave} variant="contained" sx={{ mt: 2, width: '136px' }}>
          Save
        </Button>
      </div>
    </BaseModal>
  );
};

export default SaveChangesModal;
