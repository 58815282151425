import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FirmListState {
  firmList: string[];
}

const initialState: FirmListState = {
  firmList: []
};

export const firmListSlice = createSlice({
  name: 'firmList',
  initialState,
  reducers: {
    setCurrentFirmList: (state, action: PayloadAction<string[]>) => {
      state.firmList = action.payload;
    },
    resetFirmList: (state) => {
      state.firmList = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentFirmList, resetFirmList } = firmListSlice.actions;

export default firmListSlice.reducer;
