import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FirmState {
  currentFirm: string | null;
}

const initialState: FirmState = {
  currentFirm: null
};

export const firmSlice = createSlice({
  name: 'currentFirm',
  initialState,
  reducers: {
    setCurrentFirm: (state, action: PayloadAction<string | null>) => {
      state.currentFirm = action.payload;
    },
    resetCurrentFirm: (state) => {
      state.currentFirm = null;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentFirm, resetCurrentFirm } = firmSlice.actions;

export default firmSlice.reducer;
