import { Tooltip } from '@mui/material';
import styles from '../../../../../index.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

const PostingTooltip = () => {
  return (
    // <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
    <Tooltip
      placement="right"
      title="This is the posting date that affects the financial statements."
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: styles.mainGrey,
            color: styles.mainWhite,
            maxWidth: 'none',
            whiteSpace: 'pre-wrap',
            fontSize: '10px'
          }
        },
        popper: {
          sx: {
            maxWidth: '700px'
          }
        }
      }}
    >
      <InfoOutlinedIcon sx={{ color: styles.mainRed, fontSize: '1rem', marginBottom: '0.5rem' }}/>
    </Tooltip>
    // </Box>
  );
};

export default PostingTooltip;
