import { DateTime } from 'luxon';

export function formatTimeOffset(timeZone?: string, short = false) {
  if (!timeZone) {
    return '--';
  }
  const dt = DateTime.now().setZone(timeZone);
  const offset = dt.offset / 60;
  const isOffsetWholeNumber = offset % 1 === 0;
  const offsetFormatted = `UTC${offset >= 0 ? '+' : ''}${isOffsetWholeNumber ? Math.trunc(offset) : offset}`;
  if (short) {
    return offsetFormatted;
  }
  const timeZoneAbbreviation = dt.offsetNameShort;
  return `${timeZoneAbbreviation} (${offsetFormatted})`;
}
